/**
 * Function to check all the unique values in an array of objects
 * @param {Array of Objects} list -
 * @param {String} checkAgainst
 */
const extractUniqueValues = (list, checkAgainst) => {
  const uniqueValues = [];

  const explodedValues = [];
  list.forEach((result) => {
    // split string into array in the comma
    let temporal;
    if (!result)  return;
    if (typeof result[checkAgainst] === 'string') {
      temporal = result[checkAgainst].split(",");
    } else if (Array.isArray(result[checkAgainst])) {
      temporal = result[checkAgainst];
    } else {
      return;
    }
    // push them into explodedValues
    temporal.forEach((value) => {
      const trimmed = value.trim();
      explodedValues.push(trimmed);
    });
  });

  // Check if the value in inside `uniqueValues` if not push it
  explodedValues.forEach((result) => {
    const isInsideCurrentTypesArray = uniqueValues?.some(
      (type) => type === result
    );
    if (!isInsideCurrentTypesArray && result.length !== 0) {
      uniqueValues.push(result);
    }
  });
  let retifiedValues = uniqueValues.map((value) => value.replace("&amp;", "&"));
  retifiedValues = retifiedValues.sort();
  return retifiedValues;
};

export default extractUniqueValues;
